import React from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import styled from 'styled-components';

import { ThemedSegment } from 'components';

import Toolbar from './components/Toolbar';

const InputSignWrapper = styled.div`
  display: flex;
  min-width: 350px;
`;

const ToolbarWrapper = styled.div`
  margin-right: 1em;
  display: inline-flex;
  flex-direction: column;

  & > button {
    min-width: 135px;
  }
`;

const SignPadWrapper = styled(ThemedSegment)`
  && {
    flex: 1;
    margin-top: 0;
    padding: 0;
    position: relative;
  }
`;

//const Overlay = styled.div`
//  background-color: rgba(0, 0, 0, 0.3);
//  color: #fff;
//  position: absolute;
//  width: 100%;
//  padding: 5px;
//`;

const InputSign = ({
  signRef,
  fis,
  signOptions,
  handleClear,
  //locked,
  //handleLock,
  //handleUndo,
}) => {
  return (
    <>
      <label>{fis.name}</label>
      <ToolbarWrapper>
        <Toolbar
          signRef={signRef}
          handleClear={handleClear}
          //locked={locked}
          //handleLock={handleLock}
          //handleUndo={handleUndo}
        />
      </ToolbarWrapper>
      <InputSignWrapper>
        <SignPadWrapper>
          {/*locked && <Overlay>Locked</Overlay>*/}
          <SignaturePad ref={signRef} options={signOptions} />
        </SignPadWrapper>
      </InputSignWrapper>
    </>
  );
};

export default InputSign;
