import React, { useRef, Fragment } from 'react';
import { object } from 'prop-types';
import { Table, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import _ from 'lodash';

import Utils from 'core/utils/utils';
import { ContextMenuTrigger, ThemedPopup } from 'components';

import EventLabel from './components/EventLabel';
import { getDateTimeFormatted } from '../../../../../../core/utils/date';

const ProgressCell = styled(Table.Cell)`
  &&& {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledProgress = styled.div`
  &&& {
    margin: 0;
    height: 5px;
    width: 100%;
    background-color: ${({ theme: { color } }) => color.timeline};
  }
`;

const StyledLabelDiv = styled.div`
  position: absolute;
  top: 0;
  margin: 0;
  height: 100%;
  width: 100%;
`;

const StyledPopupContent = styled(ThemedPopup.Content)`
  &&&&&&&& {
    font-size: 12px;
    line-height: 1.4285em;
  }
`;

const stringToSecond = time =>
  time
    ? time.split(':').reduce((acc, time) => (60 * acc + +time) * 1000)
    : undefined;

const dateToTime = jsDate => {
  const dateString = jsDate ? getDateTimeFormatted(jsDate) : undefined;
  return jsDate ? dateString.split(' ')[1] : undefined;
};

const dateToSecond = jsDate => {
  const time = dateToTime(jsDate);
  return stringToSecond(time);
};

const TimelineProgress = ({ view, data, handleDoubleClick }) => {
  const progressRef = useRef(null);

  const { start, end } = view;
  const minHour = start - 1;
  const maxHour = end;
  const timelineStart = stringToSecond(`${Utils.toHourString(minHour)}:30`);
  const timelineEnd = stringToSecond(`${Utils.toHourString(maxHour)}:30`);

  const getLabelProps = event => {
    let offset = 0,
      left = 0,
      width = 0,
      compact = true;
    const { start, length, end } = event;

    if (progressRef.current) {
      const totalWidth = progressRef.current.offsetWidth;

      const timeStartVal = dateToSecond(start);
      const timeEndVal = dateToSecond(end);
      const timeLengthVal = dateToSecond(length);

      const widthTime = timelineEnd - timelineStart;

      if (totalWidth) {
        offset = timeStartVal - timelineStart;
        left = (offset / widthTime) * 100;
        width =
          ((timeLengthVal || timeEndVal - timeStartVal) / widthTime) * 100;
        compact = (width / 100) * totalWidth < 35 ? true : false;
      }
    }

    return {
      left,
      width,
      compact,
    };
  };

  const { labels } = data;

  return (
    <ProgressCell colSpan="24" verticalAlign="middle">
      <StyledProgress ref={progressRef} />
      <StyledLabelDiv>
        {_.map(labels, lbl => {
          return (
            <ContextMenuTrigger
              id={lbl.key}
              key={lbl.key}
              renderTag={Popup}
              attributes={{
                position: 'bottom center',
                trigger: (
                  <EventLabel
                    {...getLabelProps(lbl)}
                    empty={lbl.text ? undefined : true}
                    content={lbl.text}
                    backgroundColor={lbl.color}
                    onDoubleClick={e => {
                      e.stopPropagation();
                      handleDoubleClick(lbl);
                    }}
                  />
                ),
              }}
            >
              <StyledPopupContent>
                {`${dateToTime(lbl.start)} - ${dateToTime(lbl.end)}`}
                {_.map(lbl.text.split('\n'), (line, index) => (
                  <Fragment key={index}>
                    <br />
                    {line}
                  </Fragment>
                ))}
              </StyledPopupContent>
            </ContextMenuTrigger>
          );
        })}
      </StyledLabelDiv>
    </ProgressCell>
  );
};

TimelineProgress.propTypes = {
  data: object.isRequired,
};

export default TimelineProgress;
