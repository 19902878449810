import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import _ from 'lodash';

import Log from 'core/utils/log';
import Utils from 'core/utils/utils';
import { FIELD_ACTION_TAG, FIELD_TYPE } from 'core/utils/constant';
import { typeMapper } from 'core/utils/mapper';

const { Types, Creators } = createActions({
  setFormData: ['formData'],
  updateFormData: ['event', 'field'],
  updateFormDataValue: ['name', 'value'],
  setVisibleFields: ['visibleFields'],
  updateVisibleField: ['tag', 'key', 'isVisible'],
  setVisibleSections: ['visibleSections'],
  setVisibleCategories: ['visibleCategories'],
  clearFormData: [],
  updateFormDataStore: ['formData'],
  //print: [],
  //resetPrint: [],
});

export const FormDataCreators = Creators;
export const FormDataTypes = Types;

export const INITIAL_STATE = Immutable({
  formData: {},
  visibleFields: [],
  visibleSections: [],
  focusedInput: '',
  //printed: false,
});

const getFileBase64 = (file, callback) => {
  // read file upload
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    if (file.type.startsWith('image/')) {
      // is image then load preview
      const img = document.createElement('img');
      img.src = reader.result;
      img.onload = () => {
        // resize file
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 768;

        let width = img.width;
        let height = img.height;

        if (width / MAX_WIDTH > height / MAX_HEIGHT) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        // create new resized file
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // convert to base64
        const dataurl = canvas.toDataURL('image/png');

        // trigger callback with base64 image file data
        callback(dataurl);
      };
    } else {
      // is others file
      callback(reader.result);
    }
  };
  reader.onerror = function(error) {
    Log.error('Error: ', error); // TODO popup error ?
  };
};

const base64EncodeUnicode = str => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  const utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(
    _match,
    p1
  ) {
    return String.fromCharCode('0x' + p1);
  });

  return btoa(utf8Bytes);
};

export const clearFormData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    formData: {},
    visibleFields: [],
    visibleSections: [],
  };
};

export const updateFormDataValue = (state = INITIAL_STATE, action) => {
  const { name, value } = action;
  const cloneFormData = { ...state.formData };

  cloneFormData[name] = value;

  return {
    ...state,
    formData: cloneFormData,
  };
};

export const updateFormData = (state = INITIAL_STATE, action) => {
  const { event, field } = action;
  const cloneFormData = { ...state.formData };

  if (_.includes([FIELD_TYPE.checkbox], typeMapper(field.type))) {
    cloneFormData[field.name] = field.checked;
  } else if (
    _.includes([FIELD_TYPE.file, FIELD_TYPE.photo], typeMapper(field.type))
  ) {
    if (event.target && event.target.files && event.target.files.length === 1) {
      const file = event.target.files[0];

      getFileBase64(file, result => {
        // set state of this field photo
        cloneFormData[field.name] = result;

        const splitExtension = file.name.split('.');
        const extension = splitExtension.pop();

        // rebuild the name and remove all special character
        //const fileName = splitExtension.join('').replace(/[`!@^&/\\#,+\-()$~%.'":*?<>{}[\]=_|;\s]+/g, '');

        // rebuild the name => encode it to bae64 => remove the special character in base64 => substring to ensure the name length fit in DB
        let fileName = base64EncodeUnicode(splitExtension.join('.')).replace(
          /[^a-zA-Z0-9]/g,
          ''
        );
        fileName = fileName.length > 150 ? fileName.substr(0, 150) : fileName;

        // add extension to the name
        cloneFormData[field.name + '_fileName'] = fileName + '.' + extension;
      });
    } else {
      // if cancel then re-initialize value of its state
      cloneFormData[field.name] = '';
      cloneFormData[field.name + '_fileName'] = '';
    }
  } else if (field.type === 'datetime-local' && field.value.length === 16) {
    // if in format yyyy-MM-ddTHH:mm, then add seconds (:ss) at the end
    cloneFormData[field.name] = `${field.value}:00`;
  } else if (_.includes([FIELD_TYPE.sign], typeMapper(field.type))) {
    cloneFormData[field.key] = field.value;
  } else {
    cloneFormData[field.name] = field.value;
  }

  return {
    ...state,
    focusedInput: field.name,
    formData: cloneFormData,
  };
};

export const setFormData = (state = INITIAL_STATE, action) => {
  const { formData } = action;

  return {
    ...state,
    formData: formData,
  };
};

//export const print = (state = INITIAL_STATE, action) => {
//  return {
//    ...state,
//    printed: true,
//  };
//};

//export const resetPrint = (state = INITIAL_STATE, action) => {
//  return {
//    ...state,
//    printed: false,
//  };
//};

export const setVisibleFields = (state = INITIAL_STATE, action) => {
  const { visibleFields } = action;

  return {
    ...state,
    visibleFields: visibleFields,
  };
};

export const setVisibleSections = (state = INITIAL_STATE, action) => {
  const { visibleSections } = action;

  return {
    ...state,
    visibleSections: visibleSections,
  };
};

export const setVisibleCategories = (state = INITIAL_STATE, action) => {
  const { visibleCategories } = action;

  return {
    ...state,
    visibleCategories: visibleCategories,
  };
};

export const updateVisibleField = (state = INITIAL_STATE, action) => {
  const { tag, key, isVisible } = action;
  const cloneVisibleFields = [...state.visibleFields];
  const cloneVisibleSections = [...state.visibleSections];
  const cloneVisibleCategories = [...state.visibleCategories];

  let eltKey = key;

  if (tag === FIELD_ACTION_TAG.category) {
    const { fieldKey, catKey } = Utils.splitKeyAutoHideForCategory(eltKey);
    eltKey = fieldKey;
    const fieldCat = _.find(cloneVisibleCategories, { name: eltKey });

    if (fieldCat) {
      updateVisibleElt(fieldCat.categories, catKey, isVisible);
    }
  } else {
    updateVisibleElt(
      tag === FIELD_ACTION_TAG.field
        ? cloneVisibleFields
        : cloneVisibleSections,
      eltKey,
      isVisible
    );
  }

  return {
    ...state,
    focusedInput:
      tag === FIELD_ACTION_TAG.field || tag === FIELD_ACTION_TAG.category
        ? eltKey
        : '',
    visibleFields: cloneVisibleFields,
    visibleSections: cloneVisibleSections,
    visibleCategories: cloneVisibleCategories,
  };
};

const updateVisibleElt = (arr, key, isVisible) => {
  const visibleElts = _.filter(arr, { name: key });

  if (visibleElts) {
    _.forEach(visibleElts, e => (e.value = isVisible));
  }
};

const updateFormDataStore = (state = INITIAL_STATE, action) => {
  const {
    formData,
    visibleFields,
    visibleSections,
    focusedInput,
  } = action.formData;
  return { ...state, formData, visibleFields, visibleSections, focusedInput };
};

export const HANDLERS = {
  [Types.UPDATE_FORM_DATA]: updateFormData,
  [Types.UPDATE_FORM_DATA_VALUE]: updateFormDataValue,
  [Types.SET_FORM_DATA]: setFormData,
  [Types.SET_VISIBLE_FIELDS]: setVisibleFields,
  [Types.SET_VISIBLE_SECTIONS]: setVisibleSections,
  [Types.SET_VISIBLE_CATEGORIES]: setVisibleCategories,
  [Types.UPDATE_VISIBLE_FIELD]: updateVisibleField,
  [Types.CLEAR_FORM_DATA]: clearFormData,
  [Types.UPDATE_FORM_DATA_STORE]: updateFormDataStore,
  //[Types.PRINT]: print,
  //[Types.RESET_PRINT]: resetPrint,
};

export default createReducer(INITIAL_STATE, HANDLERS);

// SELECTORS
const selectorFormData = state => state.formData;
//const selectorPrinted = state => state.formData.printed;

//export const getFormData = createSelector(
//  [selectorFormData],
//  formData => formData
//);

export const getVisibleFormData = createSelector(
  [selectorFormData],
  ({ formData, visibleFields, visibleSections, visibleCategories }) => ({
    formData,
    visibleFields,
    visibleSections,
    visibleCategories,
  })
);

//export const getPrinted = createSelector(
//  [selectorPrinted],
//  printed => printed
//);
