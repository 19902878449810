import React from 'react';
import { useTranslation } from 'react-i18next';

import { ThemedButton } from 'components';

const Toolbar = ({
  handleClear /*, signRef, locked, handleLock, handleUndo */,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ThemedButton
        content={t('common|Remove')}
        onClick={handleClear}
        icon="delete"
        size="small"
      />
      {
        //<ThemedButton
        //  primary
        //  content={t('common|Undo')}
        //  onClick={handleUndo}
        //  icon="undo"
        //  size="small"
        ///>
        //<ThemedButton
        //  primary
        //  content={locked ? t('common|Unlock') : t('common|Lock')}
        //  onClick={handleLock}
        //  icon="lock"
        //  size="small"
        //  toggle
        //  active={locked}
        ///>
      }
    </>
  );
};

export default Toolbar;
