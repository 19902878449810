import React, { useRef /*, useCallback, useEffect,  useState*/ } from 'react';
import { object, func } from 'prop-types';

import InputSign from './InputSign';

//const KEYCODE_Z = 90;

const InputSignContainer = ({ fis, onChange }) => {
  const signRef = useRef();
  //const [locked, setLocked] = useState(false);

  const handleClear = () => {
    if (signRef /* && !locked*/) {
      signRef.current.instance.clear();
    }
  };

  //const handleUndo = useCallback(() => {
  //  if (signRef && !locked) {
  //    const { instance } = signRef.current;

  //    let data = instance.toData();

  //    if (data) {
  //      data.pop();
  //      instance.fromData(data);
  //    }
  //  }
  //}, [locked]);

  const handleSignChange = (e, field) => {
    e.preventDefault();
    // save image as PNG
    field.value = signRef.current.instance.toDataURL();
    onChange(e, field);
  };

  //const handleLock = () => {
  //  setLocked(locked => !locked);
  //};

  //const handleKeyDown = useCallback(
  //  e => {
  //    if ((e.ctrlKey || e.metaKey) && e.keyCode === KEYCODE_Z) {
  //      handleUndo();
  //    }
  //  },
  //  [handleUndo]
  //);

  //useEffect(() => {
  //  document.addEventListener('keydown', handleKeyDown, false);

  //  return () => {
  //    document.removeEventListener('keydown', handleKeyDown, false);
  //  };
  //}, [handleKeyDown]);

  //useEffect(() => {
  //  if (signRef) {
  //    const { instance } = signRef.current;

  //    if (locked) {
  //      instance.off();
  //    } else {
  //      instance.on();
  //    }
  //  }
  //}, [signRef, locked]);

  const signOptions = {
    penColor: 'rgb(66, 133, 244)',
    onEnd: e => {
      handleSignChange(e, fis);
    },
  };

  return (
    <InputSign
      signRef={signRef}
      fis={fis}
      signOptions={signOptions}
      handleClear={handleClear}
      //locked={locked}
      //handleLock={handleLock}
      //handleUndo={handleUndo}
    />
  );
};

InputSignContainer.propTypes = {
  fis: object.isRequired,
  onChange: func.isRequired,
  //formData: object.isRequired,
};

export default InputSignContainer;
